<template>
  <div id="railyard">
    <heading v-if="showBackButton" :company="currentCompany" />

    <div class="search-container">
      <h3>How Can We Help?</h3>

      <div class="input">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="search-icon-container">
              <b-icon icon="search" class="search-icon" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            v-model="query"
            class="search-stages"
            placeholder="Search By Stage Name"
            @input="debounceSearch"
          />
        </b-input-group>
      </div>
    </div>

    <div class="task-list">
      <b-button
        v-for="stage in stages"
        :key="stage.id"
        class="task-container"
        :aria-label="stage.accounts_stage?.current_slide_id ? 'In-Progress' : 'New'"
        @click="goToStage(stage)"
      >
        <div class="stage">
          <span class="stage-title">
            {{ stage.title }}
          </span>

          <div class="stage-description">
            {{ stage.description }}
          </div>

          <div class="badges">
            <div v-if="stage.accounts_stage?.current_slide_id" class="badge in-progress">
              In-Progress
            </div>
          </div>
        </div>

        <fa-icon icon="chevron-right" class="icon" />
      </b-button>
    </div>
    <div class="load-more">
      <ct-centered-spinner v-if="loading">
        Loading Stages...
      </ct-centered-spinner>
      <b-button
        v-else-if="showLoadMore"
        class="load-more-button"
        @click="loadStages()"
      >
        Load More
        <span class="ellipse">...</span>
      </b-button>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StageViewer',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
    Heading:           () => import('@/components/StagelineV3/Heading.vue'),
  },
  data() {
    return {
      query: '',
      showBackButton: false,
      loading: true,
      offset: 1,
      typing: false,
      debounce: null,
      lastSearchedTerm: null,
      showLoadMore: true,
      stageLimit: 9,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('stagelineV3', [
      'stages',
    ]),
  },
  watch: {
    lastSearchedTerm() {
      this.offset = 1
      this.showLoadMore = true
      this.search()
    },
  },
  async mounted() {
    this.companyId = this.$route.params.companyId
    if (this.companyId && this.companyId !== this.currentCompany?.id) {
      await this.setCurrentCompany({ id: this.companyId })
    }

    this.showBackButton = true

    await this.loadStages(true)
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stagelineV3', [
      'getStages',
    ]),
    async loadStages(clear = false) {
      this.loading = true
      try {
        const previousLength = this.stages.length
        await this.getStages({
          limit: this.stageLimit,
          include: 'accounts_stage',
          company_id: this.currentCompany.id,
          title: this.lastSearchedTerm,
          page: this.offset,
          clear,
        })
        if (this.stages.length === previousLength || this.stages.length % this.stageLimit !== 0) this.showLoadMore = false
        this.offset += 1
      } catch {
        // do nothing for now
      } finally {
        this.loading = false
      }
    },
    async goToStage(stage) {
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.currentCompany.id,
          stageId: stage.id,
        },
      })
    },
    debounceSearch() {
      this.resultsLoaded = false
      this.typing = true

      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.lastSearchedTerm = this.query
      }, 500)
    },
    async search() {
      await this.loadStages(true)
    },
  },
}
</script>

<style scoped lang="scss">
#railyard {
  margin: 5em 15%;
  overflow: hidden;
  width: 100%;

  .search-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;
    padding: 0;
    margin: auto 0;
    width: 100%;
  }

  .input {
    margin-top: 1em;
    width: 20em;

    .search-stages {
      width: auto;
      margin-bottom: 0;
      border-left: none;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 rgb(255, 255, 255);
        border-color: #ced4da;
      }
    }

    .search-icon-container {
      background-color: $white;
      border-right: none;
      padding-right: 0;
    }
  }

  .task-list {
    display: grid;
    margin-top: 2em;
    grid-template-columns: repeat(3, minmax(15em, 1fr));
    gap: 1em;

    .task-container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex: 0 0 auto;
      gap: 1em;
      width: 100%;
      flex-direction: row;
      padding: 1em;
      border: 2px solid lightgray;
      border-radius: 0.5em;
      background-color: white;

      .badges {
        min-height: 1em;
        margin-bottom: 0.75em;
        display: flex;
        flex-wrap: wrap;
        max-width: 12em;
        gap: 0.25em;

        .badge {
          margin-top: 1em;
          font-size: 0.625em;
          padding: 0.5em;
          border-radius: 1em;
        }

        .in-progress {
          background: #33B3AF40;
          color: #009FBF;
        }

        .new {
          background: #33B34A40;
          color: #009F4F;
        }

        .urgent {
          background: #F93F3629;
          color: #CB2328;
        }
      }

      .stage {
        color: black;
        text-align: left;
        align-items: center;

        .stage-title {
          margin-right: 1em;
          font-size: 1.5em;
          margin-bottom: 0.5em;
          font-weight: 600;
        }
      }

      .icon {
        color: $black;
      }

      &:hover {
        border-color: $ct-ui-primary;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);

        .icon {
          color: $ct-ui-primary;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .load-more {
    height: 6em;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .ellipse {
      position: relative;
      top: -0.0625em;
      font-weight: 800;
      font-size: 2em;
    }

    .load-more-button {
      height: 3em;
      color: $ct-ui-primary;
      background-color: transparent;
      border: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  @media only screen and (max-width: 1157px) {
    .task-list {
      grid-template-columns: repeat(2, minmax(15em, 1fr));
    }
  }

  @media only screen and (max-width: 768px) {
    .task-list {
      grid-template-columns: repeat(1, minmax(15em, 1fr));
    }

    .container {
      justify-content: center;
    }
  }
}
</style>
