var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "railyard" } },
    [
      _vm.showBackButton
        ? _c("heading", { attrs: { company: _vm.currentCompany } })
        : _vm._e(),
      _c("div", { staticClass: "search-container" }, [
        _c("h3", [_vm._v("How Can We Help?")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c(
                      "b-input-group-text",
                      { staticClass: "search-icon-container" },
                      [
                        _c("b-icon", {
                          staticClass: "search-icon",
                          attrs: { icon: "search" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-form-input", {
                  staticClass: "search-stages",
                  attrs: { placeholder: "Search By Stage Name" },
                  on: { input: _vm.debounceSearch },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "task-list" },
        _vm._l(_vm.stages, function (stage) {
          return _c(
            "b-button",
            {
              key: stage.id,
              staticClass: "task-container",
              attrs: {
                "aria-label": stage.accounts_stage?.current_slide_id
                  ? "In-Progress"
                  : "New",
              },
              on: {
                click: function ($event) {
                  return _vm.goToStage(stage)
                },
              },
            },
            [
              _c("div", { staticClass: "stage" }, [
                _c("span", { staticClass: "stage-title" }, [
                  _vm._v("\n          " + _vm._s(stage.title) + "\n        "),
                ]),
                _c("div", { staticClass: "stage-description" }, [
                  _vm._v(
                    "\n          " + _vm._s(stage.description) + "\n        "
                  ),
                ]),
                _c("div", { staticClass: "badges" }, [
                  stage.accounts_stage?.current_slide_id
                    ? _c("div", { staticClass: "badge in-progress" }, [
                        _vm._v("\n            In-Progress\n          "),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("fa-icon", {
                staticClass: "icon",
                attrs: { icon: "chevron-right" },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "load-more" },
        [
          _vm.loading
            ? _c("ct-centered-spinner", [
                _vm._v("\n      Loading Stages...\n    "),
              ])
            : _vm.showLoadMore
            ? _c(
                "b-button",
                {
                  staticClass: "load-more-button",
                  on: {
                    click: function ($event) {
                      return _vm.loadStages()
                    },
                  },
                },
                [
                  _vm._v("\n      Load More\n      "),
                  _c("span", { staticClass: "ellipse" }, [_vm._v("...")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }